import React from "react";
import Confetti from "react-dom-confetti";
import reviews from "./extracted_reviews.json";
import Marquee from "react-fast-marquee";

const ENGLISH_ALPHABET = "BCDEFGHIJKLMNPQRSTUVWXZ".split("");
const INDEXES = [
  {
    name: "Y",
    index: 2,
  },
  {
    name: "O",
    index: 5,
  },
  {
    name: "A",
    index: 8,
  },
];

const config = {
  angle: 90,
  spread: 360,
  startVelocity: 40,
  elementCount: "120",
  dragFriction: 0.12,
  duration: "4340",
  stagger: "9",
  width: "12px",
  height: "13px",
  perspective: "1000px",
  colors: ["#a864fd", "#29cdff", "#78ff44", "#ff718d", "#fdff6a"],
};

function shuffleArray(array) {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]]; // Swap elements
  }
  return array;
}

class App extends React.Component {
  constructor(props) {
    super(props);
    let text = "CR PT GR M".split("");

    const screenWidth = window.innerWidth;
    const maxContentLength = Math.floor(screenWidth * 0.5);
    console.log(maxContentLength);

    // Inside your render function or component:
    const shuffledReviews = shuffleArray([...reviews]);
    const selectedReviews = shuffledReviews.filter(
      (x) => x.content.length > 10 && x.content.length < maxContentLength,
    );
    this.state = {
      confettiVisible: false,
      text: text,
      selectedReviews,
      emptyIndexes: text
        .map((x, index) => index)
        .filter((x, index) => text[index] == " "),
    };
    this.step = 0;
  }

  // async sleep function
  async sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  async startChanging(currentCount = 0, maxCount = 10) {
    // sleep random time
    await this.sleep(Math.random() * 200);

    // check if 5th element exists on INDEXES array
    if (INDEXES[this.step] === undefined)
      return this.setState({ confettiVisible: true });

    let randomLetterFromAlphabet =
      ENGLISH_ALPHABET[Math.floor(Math.random() * ENGLISH_ALPHABET.length)];

    let final = this.state.text;
    final[INDEXES[this.step].index] = randomLetterFromAlphabet;
    this.setState({ text: final });

    currentCount++;
    if (currentCount == maxCount) {
      let final = this.state.text;
      final[INDEXES[this.step].index] = INDEXES[this.step].name;
      this.step++;
      this.setState({ text: final });
      currentCount = 0;
      maxCount = Math.floor(Math.random() * 7) + 3;
    }
    this.startChanging(currentCount, maxCount);
  }

  componentDidMount() {
    this.startChanging();
  }

  renderWord(index) {
    let top = this.state.text[index];
    let below = !this.state.emptyIndexes.includes(index) == " " ? "empty" : "";
    return (
      <div className="textbox col" key={index}>
        <h1
          className={`mainText ${
            INDEXES[this.step]?.index == index ? "active" : ""
          } ${below}`}
        >
          {top}
        </h1>
      </div>
    );
  }
  renderTestimonial(review, index) {
    const baseWidth = 200; // base width in pixels
    const additionalWidthPerChar = 1; // adjust this based on your needs
    const maxLengthForBaseWidth = 100; // adjust this based on your needs
    const maxWidth = window.innerWidth - 40; // keeping 20px margin on each side

    // Calculate width
    const contentLength = review.content.length;
    let calculatedWidth =
      contentLength <= maxLengthForBaseWidth
        ? baseWidth
        : baseWidth +
          (contentLength - maxLengthForBaseWidth) * additionalWidthPerChar;

    calculatedWidth = Math.min(calculatedWidth, maxWidth);

    return (
      <div
        key={index}
        style={{
          position: "relative",
          padding: "15px",
          margin: "20px 10px",
          boxShadow: "0px 3px 10px rgba(0, 0, 0, 0.2)",
          borderRadius: "8px",
          backdropFilter: "blur(10px)",
          border: "1px solid rgba(255, 255, 255, 0.2)",
          height: "auto",
          width: `${calculatedWidth}px`, // Use calculated width
        }}
      >
        {/* Star Rating */}
        <div
          style={{
            marginBottom: "10px",
          }}
        >
          {Array(5)
            .fill()
            .map((_, i) => (
              <i className="fas fa-star star" key={i} style={{ "--i": i }}></i> // Font Awesome Star Icon
            ))}
        </div>
        {/* Review Content */}
        <div className="test-testimonials">{review.content}</div>
      </div>
    );
  }

  renderTestimonials() {
    return (
      <div className="middle bottom-testimonials">
        <Marquee pauseOnHover={true} loop={0}>
          {this.state.selectedReviews.map((x, y) =>
            this.renderTestimonial(x, y),
          )}
        </Marquee>
      </div>
    );
  }

  render() {
    return (
      <div>
        <div className="d-flex flex-column min-vh-100 justify-content-center align-items-center">
          <Confetti active={this.state.confettiVisible} config={config} />
          <div
            className="middle d-flex flex-column"
            style={{
              flex: 1,
              alignItems: "center",
              justifyContent: "center",
              paddingTop: "100px",
            }}
          >
            <div className="row mainRow">
              {this.state.text.map((x, index) => this.renderWord(index))}
            </div>

            <div
              className="row justify-content-center image-container"
              style={{ marginTop: 22 }}
            >
              <a
                target="_blank"
                href="https://apps.apple.com/ca/app/cryptogram-decrypt-quotes/id1549190465"
                rel="noreferrer"
              >
                <img
                  className="image-container-apple"
                  src="/app_store_badge.svg"
                />
              </a>
              <a href="https://play.google.com/store/apps/details?id=com.jmsc.cryptogram&hl=tr&gl=US&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1">
                <img
                  className="image-container-google"
                  alt="Get it on Google Play"
                  src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png"
                />
              </a>
            </div>

            <div
              className="row justify-content-center"
              style={{ marginTop: 12 }}
            >
              <button
                onClick={() =>
                  window.open("https://docs.cryptogrm.com/docs/intro")
                }
                className="docButton"
              >
                <p>DOCS</p>
              </button>

              <button onClick={() => this.sendMail()} className="docButton">
                <p>SUPPORT</p>
              </button>
            </div>
          </div>
          {this.renderTestimonials()}
        </div>
      </div>
    );
  }

  sendMail() {
    var link =
      "mailto:jmsc.tty@gmail.com" +
      "?subject=" +
      encodeURIComponent("Cryptogram Support");
    window.location.href = link;
  }
}

export default App;
